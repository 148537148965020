<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <div class="row mt-2">
                <div class="row">
                  <div class="col-md-6">
                    <h5 class="font-size-18 mb-1">ข้อมูลลูกค้า</h5>
                  </div>
                  <div class="col-md-6 text-end">
                    <span
                      class="badge bg-warning text-dark font-size-12"
                      v-if="this.status == 'DFT'"
                      >ร่าง</span
                    >
                    <span
                      class="badge bg-info text-dark font-size-12"
                      v-if="this.status == 'WIP'"
                      >รอดำเนินการ</span
                    >
                    <span
                      class="badge bg-success font-size-12"
                      v-if="this.status == 'SCS'"
                      >เสร็จสิ้น</span
                    >
                  </div>
                </div>
                <div class="col-md-4 col-sm-6">
                  <div class="mb-3 position-relative">
                    <code>* </code><label for="branchId">สาขา:</label>
                    <multiselect
                      disabled
                      id="branchId"
                      v-model="tooltipform.branchId"
                      :options="localData"
                      :show-labels="false"
                      label="nameTh"
                      track-by="nameTh"
                      @input="getSearch()"
                    >
                      <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                    >
                  </div>
                </div>
                <div class="col-md-4 col-sm-6">
                  <div class="row align-items-center">
                    <div class="col-md-9 col-sm-9">
                      <div class="mb-3 position-relative">
                        <code>* </code><label for="nameTh">ชื่อลูกค้า</label>:
                        <multiselect
                          :disabled="this.status == 'SCS' || this.status == 'L'"
                          v-model="tooltipform.cusId"
                          :options="customerOption"
                          label="nameTh"
                          track-by="nameTh"
                          :show-labels="false"
                          :custom-label="customLabelCus"
                          @search-change="getCustomer($event)"
                          placeholder="ค้นหาชื่อลูกค้าทั่วไป"
                          @input="selectCusNoArr(tooltipform.cusId)"
                        >
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-3">
                      <div class="mt-2 position-relative">
                        <b-button
                          :disabled="this.status == 'SCS' || this.status == 'L'"
                          class="btn"
                          variant="info"
                          v-b-modal.modalCus
                        >
                          <i class="uil-file-search-alt"></i
                        ></b-button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-md-4 col-sm-6">
                  <div class="row align-items-center">
                    <div class="col-md-9 col-sm-9">
                      <div class="mb-3 position-relative">
                        <code>* </code><label for="nameTh">ชื่อลูกค้ามุ่งหวัง</label
                        >:
                        <multiselect
                          :disabled="this.status == 'SCS' || this.status == 'L'"
                          v-model="tooltipform.ppCusId"
                          :options="prospectOption"
                          label="nameTh"
                          track-by="nameTh"
                          :show-labels="false"
                          :custom-label="customLabelCus"
                          placeholder="ค้นหาชื่อลูกค้ามุ่งหวัง"
                          @search-change="getProspect($event)"
                          @input="selectNoArr(tooltipform.ppCusId)"
                            :class="{
                            'is-invalid':
                              submitform && $v.tooltipform.ppCusId.$error,
                          }"
                        >
                        </multiselect>
                         <div
                          v-if="submitform && $v.tooltipform.ppCusId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.tooltipform.ppCusId.required">{{
                            error
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-3">
                      <div class="mt-2 position-relative">
                        <b-button
                          :disabled="this.status == 'SCS' || this.status == 'L'"
                          class="btn"
                          variant="info"
                          v-b-modal.modalPropect
                        >
                          <i class="uil-file-search-alt"></i
                        ></b-button>
                      </div>
                    </div>
                  </div>
                </div> -->

                <div class="col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="mobilePhone">เบอร์โทรศัพท์:</label>
                    <input
                      disabled
                      maxlength="10"
                      v-model="tooltipform.mobilePhone"
                      type="tel"
                      class="form-control input-placeholder"
                      placeholder="เบอร์โทรศัพท์แบบไม่มีขีด"
                    />
                  </div>
                </div>

                <div class="col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltipemail">อีเมล:</label>
                    <input
                      disabled
                      v-model="tooltipform.email"
                      type="email"
                      class="form-control input-placeholder"
                      placeholder="ตัวอย่าง : example@mail.com"
                    />
                  </div>
                </div>

                <div class="col-md-3 col-sm-6">
                  <div class="row align-items-center">
                    <div class="col-md-9 col-sm-9">
                      <div class="mb-3 position-relative">
                        <code>* </code
                        ><label for="licensePlate">ทะเบียนรถ:</label>
                        <multiselect
                          :disabled="this.status == 'SCS' || this.status == 'L'"
                          v-model="tooltipform.vehicleId"
                          :options="vehicaleOption"
                          label="licensePlate"
                          track-by="vin"
                          :show-labels="false"
                          placeholder="ค้นหาทะเบียนรถ"
                          @search-change="getVehical($event)"
                          @input="selectVeNoArr(tooltipform.vehicleId)"
                        >
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-3">
                      <div class="mt-2 position-relative">
                        <b-button
                          :disabled="this.status == 'SCS' || this.status == 'L'"
                          class="btn"
                          variant="info"
                          v-b-modal.modalVe
                        >
                          <i class="uil-file-search-alt"></i
                        ></b-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6">
                  <div class="mb-3 position-relative">
                    <label for="vin">เลขตัวถัง:</label>
                    <input
                      disabled
                      v-model="tooltipform.vin"
                      class="form-control input-placeholder"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-sm-6">
                  <div class="mb-3 position-relative">
                    <label for="vin">ยี่ห้อ:</label>
                    <input
                      disabled
                      v-model="tooltipform.vehicleBrandTh"
                      class="form-control input-placeholder"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-sm-6">
                  <div class="mb-3 position-relative">
                    <label for="vin">รุ่น:</label>
                    <input
                      disabled
                      v-model="tooltipform.vehicleModelTh"
                      class="form-control input-placeholder"
                    />
                  </div>
                </div>

                <div class="col-md-3 col-sm-6">
                  <div class="mb-3 position-relative">
                    <code>* </code><label for="nameTh">ประเภทการติดต่อ:</label>
                    <multiselect
                      :disabled="this.status == 'SCS' || this.status == 'L'"
                      v-model="tooltipform.ctJobTypeId"
                      :options="contactTypeOption"
                      label="nameTh"
                      track-by="nameTh"
                      :show-labels="false"
                      placeholder=""
                      :class="{
                        'is-invalid':
                          submitform && $v.tooltipform.ctJobTypeId.$error,
                      }"
                    >
                    </multiselect>
                    <div
                      v-if="submitform && $v.tooltipform.ctJobTypeId.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.ctJobTypeId.required">{{
                        error
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-6">
                  <div class="mb-3 position-relative">
                    <code>* </code><label for="ctJobDate">วันที่:</label>
                    <date-picker
                      :disabled="this.status == 'SCS' || this.status == 'L'"
                      v-model="tooltipform.ctJobDate"
                      :first-day-of-week="1"
                      format="YYYY-MM-DD"
                      value-type="format"
                      lang="en"
                      :class="{
                        'is-invalid':
                          submitform && $v.tooltipform.ctJobDate.$error,
                      }"
                    >
                    </date-picker>
                    <div
                      v-if="submitform && $v.tooltipform.ctJobDate.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.ctJobDate.required">{{
                        error
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltipnote">หมายเหตุ:</label>

                    <textarea
                      :disabled="this.status == 'SCS' || this.status == 'L'"
                      v-model="tooltipform.note"
                      id="formmessage"
                      rows="3"
                      class="form-control"
                    >
                    </textarea>
                  </div>
                </div>
              </div>
              <div>
                <button
                  class="btn btn-success"
                  @click="putContactJob"
                  :disabled="this.status == 'SCS'"
                >
                  บันทึกการแก้ไข
                </button>
                &nbsp;
              </div>

              <hr />
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row mt-2">
                <div class="row">
                  <div class="col-md-6">
                    <h5 class="font-size-18 mb-1">
                      ประวัติการติดต่อ &nbsp;({{ this.rowContact.length }}
                      ครั้ง)
                    </h5>
                  </div>
                  <div class="col-md-6 text-end">
                    <button
                      class="btn btn-primary"
                      v-b-modal.modalContact
                      :disabled="this.status == 'SCS' || this.status == 'L'"
                    >
                      เพิ่มข้อมูลการติดต่อ
                    </button>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div
                  class="col-md-12"
                  v-for="(rowCt, index) in rowContact"
                  :key="'Ct' + index"
                >
                  <b>
                    ช่องทางติดต่อ :
                    <i
                      class="bx bx-mobile font-size-18"
                      v-if="rowCt.contactType == 'CALL'"
                    ></i
                    ><i
                      class="bx bx-laptop font-size-18"
                      v-if="rowCt.contactType == 'MANUAL'"
                    ></i
                  ></b>
                  <br />
                  <br />
                  <b> ผู้ติดต่อ : {{ rowCt.userName }}</b>
                  <b> [ วันที่/เวลา : {{ rowCt.createdAt }} ] </b>

                  <br />
                  <div class="row">
                    <div class="col-md-10">
                      สถานะ : {{ rowCt.contactResultTh }}(เรื่องที่ติดต่อ :
                      {{ rowCt.subject }} , {{ rowCt.message }}) <br />
                      <span>ระยะเวลาโทร : {{ time(rowCt.duration) }}</span>
                    </div>
                    <div class="col-md-2 text-end">
                      <a
                        v-if="status != 'SCS'"
                        class="px-2 text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                        @click="alert(rowCt.contactId)"
                      >
                        <i class="uil uil-trash-alt font-size-18"></i>
                      </a>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
              <div class="mb-2" v-if="rowContact.length <= 0">
                ไม่พบข้อมูลการติดต่อ
              </div>
              <hr v-if="rowContact.length <= 0" />

              <div class="row text-end">
                &nbsp;
                <div class="col-md-12 col-sm-12 col-12 mt-4">
                  <button
                    :disabled="this.status == 'SCS' || this.status == 'L'"
                    class="btn btn-danger"
                    type="submit"
                    @click="putSubmit()"
                  >
                    บันทึกปิดการติดต่อ
                  </button>
                  <p
                    v-if="this.status == 'SCS' || this.status == 'L'"
                    class="mt-2"
                  >
                    <code>(บันทึกปิดการติดต่อแล้ว)</code>
                  </p>
                </div>
              </div>
            </div>
            <b-modal
              ref="modalContact"
              id="modalContact"
              title="เพิ่มข้อมูลการติดต่อ"
              hide-footer
              size="lg"
              centered
            >
              <div class="card-body">
                <form class="needs-validation" @submit.prevent="ContactForm">
                  <div class="row">
                    <!-- <div class="col-md-6 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="mobilePhone">เบอร์โทรศัพท์:</label>
                        <input
                          maxlength="10"
                          v-model="tooltipform.mobilePhone"
                          type="tel"
                          class="form-control input-placeholder"
                          placeholder="เบอร์โทรศัพท์แบบไม่มีขีด"
                        />
                      
                      </div>
                    </div> -->
                    <div class="col-md-6 col-sm-6">
                      <div class="mb-3 position-relative">
                        <code>* </code><label for="subject">เรื่อง:</label>
                        <input
                          v-model="form.subject"
                          type="tel"
                          class="form-control"
                          :class="{
                            'is-invalid': submit && $v.form.subject.$error,
                          }"
                        />

                        <div
                          v-if="submit && $v.form.subject.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.subject.required">{{
                            error
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                      <div class="mb-3 position-relative">
                        <code>* </code
                        ><label for="message">รายละเอียดการติดต่อ:</label>
                        <textarea
                          v-model="form.message"
                          type="tel"
                          class="form-control"
                          :class="{
                            'is-invalid': submit && $v.form.message.$error,
                          }"
                        >
                        </textarea>

                        <div
                          v-if="submit && $v.form.message.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.message.required">{{
                            error
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-end">
                    <button type="submit" class="btn btn-success">
                      บันทึก
                    </button>
                    &nbsp;
                    <button class="btn btn-danger" @click="hide()">ปิด</button>
                  </div>
                </form>
              </div>
            </b-modal>
          </div>
        </b-overlay>
      </div>
    </div>
    <b-modal
      ref="modalCus"
      id="modalCus"
      title="รายชื่อลูกค้า"
      hide-footer
      size="xl"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <div class="col-sm-4 col-md-4">
                <div class="mb-3 position-relative">
                  <label class="d-inline align-items-center"> สาขา:</label>
                  <multiselect
                    v-model="tooltipform.branchId"
                    :options="localData"
                    label="nameTh"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>

              <div class="col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2">
                  ชื่อลูกค้า:
                  <b-form-input
                    v-model="filter.nameTh"
                    type="search"
                    placeholder="ชื่อ-นามสกุล"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>
              <div class="col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2">
                  นามสกุลลูกค้า:
                  <b-form-input
                    v-model="filter.familyNameTh"
                    type="search"
                    placeholder="นามสกุลลูกค้า"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>

              <div
                class="col-2 col-sm-2 col-md-2 text-end"
                style="margin-left: auto; margin-right: 0"
              >
                <b-button
                  class="btn btn ms-2"
                  variant="info"
                  type="submit"
                  @click="handleSearch"
                >
                  <i class="uil-search"></i>
                  ค้นหา
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: false, striped: true }"
              animation="throb"
            ></b-skeleton-table>
          </template>
          <div class="row mt-2">
            <!-- Search -->
            <div class="col-sm-12 col-md-12">
              <div class="row align-items-center">
                <div class="col-sm-5 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-block align-items-center">
                      แสดงผล
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        @input="handlePageChange"
                      ></b-form-select
                      >&nbsp; รายการ
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"></div>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->

          <!--  v-if="this.veh.name === 'เจ้าของรถ'" -->
          <div class="table-responsive mb-0">
            <b-table
              :items="customerOption"
              :fields="fields"
              responsive="true"
              :per-page="perPage"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              :select-mode="selectMode"
              @row-selected="selectCus"
            >
              <template #cell(regDate)="rowRegDoc">
                <changeDate :date="rowRegDoc.item.regDate"></changeDate>
              </template>
              <template #cell(index)="rowData" v-if="this.currentPage > 1">
                {{ rowData.index + 1 + (currentPage * perPage - perPage) }}
              </template>
              <template #cell(index)="rowData" v-else>
                {{ rowData.index + 1 }}
              </template>
              <template #cell(cusType)="rowUser">
                <span v-if="rowUser.item.cusType === 'P'"> บุคคล </span>
                <span v-if="rowUser.item.cusType === 'B'"> นิติบุคคล </span>
              </template>
              <template #cell(status)="rowRegDoc">
                <span
                  class="badge bg-warning font-size-12 ms-2"
                  v-if="rowRegDoc.item.status === 'DFT'"
                >
                  ร่าง
                </span>
                <span
                  class="badge bg-info font-size-12 ms-2"
                  v-if="rowRegDoc.item.status === 'SUBMIT'"
                >
                  รออนุมัติ
                </span>
                <span
                  class="badge bg-success font-size-12 ms-2"
                  v-if="rowRegDoc.item.status === 'APV'"
                >
                  ยืนยันแล้ว
                </span>
                <span
                  class="badge bg-danger font-size-12 ms-2"
                  v-if="rowRegDoc.item.status === 'CAN'"
                >
                  ยกเลิกแล้ว
                </span>
              </template>
              <!-- End edit table -->
            </b-table>
          </div>

          <div class="row">
            <span v-if="this.totalRecord === 0" class="text-center font-size-16"
              >ไม่พบรายการ</span
            >
            <div class="col" v-if="this.totalRecord > 0">
              <div class="col">
                หน้า {{ currentPage }} จาก {{ this.totalPage }} ทั้งหมด
                {{ totalRecord }} รายการ
              </div>

              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRecord"
                      :per-page="perPage"
                      @change="handleChangePage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <hr />
            <br />
          </div>
          <!-- </div> -->
        </b-skeleton-wrapper>
      </div>

      <div class="row">
        <div class="col-md">
          <button class="btn btn-success float-end">ตกลง</button>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="modalVe"
      id="modalVe"
      title="รายการรถ"
      hide-footer
      size="xl"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <div class="col-sm-4 col-md-4">
                <div class="mb-3 position-relative">
                  <label class="d-inline align-items-center"> สาขา:</label>
                  <multiselect
                    v-model="tooltipform.branchId"
                    :options="localData"
                    label="nameTh"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2 text-end">
                  ทะเบียนรถ:
                  <b-form-input
                    v-model="filter.licensePlate"
                    type="search"
                    placeholder="ทะเบียนรถ"
                    class="form-control ms-2"
                    @keyup.enter="handleSearchVe"
                  ></b-form-input>
                </label>
              </div>

              <div
                class="col-2 col-sm-2 col-md-2 text-end"
                style="margin-left: auto; margin-right: 0"
              >
                <b-button
                  class="btn btn ms-2"
                  variant="info"
                  type="submit"
                  @click="handleSearchVe"
                >
                  <i class="uil-search"></i>
                  ค้นหา
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: false, striped: true }"
              animation="throb"
            ></b-skeleton-table>
          </template>
          <div class="row mt-2">
            <!-- Search -->
            <div class="col-sm-12 col-md-12">
              <div class="row align-items-center">
                <div class="col-sm-5 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-block align-items-center">
                      แสดงผล
                      <b-form-select
                        v-model="perPageVe"
                        size="sm"
                        :options="pageOptionsVe"
                        @input="handlePageChangeVe"
                      ></b-form-select
                      >&nbsp; รายการ
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"></div>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->

          <!--  v-if="this.veh.name === 'เจ้าของรถ'" -->
          <div class="table-responsive mb-0">
            <b-table
              :items="vehicaleOption"
              :fields="fieldsVe"
              responsive="true"
              :per-page="perPageVe"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              :select-mode="selectMode"
              @row-selected="selectVe"
            >
              <template
                #cell(index)="vehicaleOption"
                v-if="this.currentPage > 1"
              >
                {{
                  vehicaleOption.index + 1 + (currentPage * perPage - perPage)
                }}
              </template>
              <template #cell(index)="vehicaleOption" v-else>
                {{ vehicaleOption.index + 1 }}
              </template>

              <!-- End edit table -->
            </b-table>
          </div>

          <div class="row">
            <span v-if="this.totalPageVe === 0" class="text-center font-size-16"
              >ไม่พบรายการ</span
            >
            <div class="col" v-if="this.totalPageVe > 0">
              <div class="col">
                หน้า {{ currentPageVe }} จาก {{ this.totalPageVe }} ทั้งหมด
                {{ this.totalRecordVe }} รายการ
              </div>

              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPageVe"
                      :total-rows="totalRecordVe"
                      :per-page="perPageVe"
                      @change="handleChangePageVe"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <hr />
            <br />
          </div>
          <!-- </div> -->
        </b-skeleton-wrapper>
      </div>

      <div class="row">
        <div class="col-md">
          <button class="btn btn-success float-end">ตกลง</button>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="modalPropect"
      id="modalPropect"
      title="รายชื่อลูกค้ามุ่งหวัง"
      hide-footer
      size="xl"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <div class="col-sm-4 col-md-4">
                <div class="mb-3 position-relative">
                  <label class="d-inline align-items-center"> สาขา:</label>
                  <multiselect
                    v-model="tooltipform.branchId"
                    :options="localData"
                    label="nameTh"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2">
                  ชื่อลูกค้า:
                  <b-form-input
                    v-model="filter.nameThPros"
                    type="search"
                    placeholder="ชื่อ-นามสกุล"
                    class="form-control ms-2"
                    @keyup.enter="handleSearchPros"
                  ></b-form-input>
                </label>
              </div>
              <div class="col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2">
                  นามสกุลลูกค้า:
                  <b-form-input
                    v-model="filter.familyNameThPros"
                    type="search"
                    placeholder="นามสกุลลูกค้า"
                    class="form-control ms-2"
                    @keyup.enter="handleSearchPros"
                  ></b-form-input>
                </label>
              </div>
              <div
                class="col-2 col-sm-2 col-md-2 text-end"
                style="margin-left: auto; margin-right: 0"
              >
                <b-button
                  class="btn btn ms-2"
                  variant="info"
                  type="submit"
                  @click="handleSearchPros"
                >
                  <i class="uil-search"></i>
                  ค้นหา
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: false, striped: true }"
              animation="throb"
            ></b-skeleton-table>
          </template>
          <div class="row mt-2">
            <!-- Search -->
            <div class="col-sm-12 col-md-12">
              <div class="row align-items-center">
                <div class="col-sm-5 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-block align-items-center">
                      แสดงผล
                      <b-form-select
                        v-model="perPagePros"
                        size="sm"
                        :options="pageOptionsPros"
                        @input="handlePageChangePros"
                      ></b-form-select
                      >&nbsp; รายการ
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"></div>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->

          <!--  v-if="this.veh.name === 'เจ้าของรถ'" -->
          <div class="table-responsive mb-0">
            <b-table
              :items="prospectOption"
              :fields="fieldsPros"
              responsive="true"
              :per-page="perPagePros"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              :select-mode="selectMode"
              @row-selected="select"
            >
              <template #cell(level)="prospectOption">
                <span v-if="prospectOption.item.level == '3'"> สูง </span>
                <span v-if="prospectOption.item.level == '2'"> กลาง </span>
                <span v-if="prospectOption.item.level == '1'"> ต่ำ </span>
                <span v-if="prospectOption.item.level == '0'"> อื่นๆ </span>
              </template>
              <template
                #cell(index)="prospectOption"
                v-if="this.currentPage > 1"
              >
                {{
                  prospectOption.index + 1 + (currentPage * perPage - perPage)
                }}
              </template>
              <template #cell(index)="prospectOption" v-else>
                {{ prospectOption.index + 1 }}
              </template>
              <template #cell(status)="prospectOption">
                <span
                  class="badge bg-warning font-size-12 ms-2"
                  v-if="prospectOption.item.status === 'A'"
                >
                  กำลังติดตาม
                </span>

                <span
                  class="badge bg-success font-size-12 ms-2"
                  v-if="prospectOption.item.status === 'C'"
                >
                  เสร็จสิ้น
                </span>
              </template>
              <!-- End edit table -->
            </b-table>
          </div>

          <div class="row">
            <span
              v-if="this.totalRecordPros === 0"
              class="text-center font-size-16"
              >ไม่พบรายการ</span
            >
            <div class="col" v-if="this.totalRecordPros > 0">
              <div class="col">
                หน้า {{ currentPagePros }} จาก {{ this.totalPagePros }} ทั้งหมด
                {{ totalRecordPros }} รายการ
              </div>

              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPagePros"
                      :total-rows="totalRecordPros"
                      :per-page="perPagePros"
                      @change="handleChangePagePros"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <hr />
            <br />
          </div>
          <!-- </div> -->
        </b-skeleton-wrapper>
      </div>

      <div class="row">
        <div class="col-md">
          <button class="btn btn-success float-end">ตกลง</button>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>

<script>
import {
  required,
  // url,
  // alphaNum,
} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    /*Switches,
    NumberInputSpinner,*/
  },
  page: {
    title: appConfig.contactCustomer,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  data() {
    return {
      Hide: false,
      enabled: false,
      submitform: false,
      submitformAddress: false,
      modalUpdateAddress: false,
      submitUpdateAddress: false,

      title: "รายละเอียดการติดต่อ",
      items: [
        {
          text: "ฝ่ายขาย",
          active: true,
        },
        {
          text: appConfig.contactCalendar,
          href: "/sale-contact-job/contactJob-calendar",
        },
        {
          text: "รายละเอียดการติดต่อ",
          active: true,
        },
      ],
      status: "",
      localDataBranch: [], //เป็น-array Id
      localData: [],
      value: null,
      value1: null,
      dataTest: [],
      options: [],
      overlayFlag: false,
      startIndex: "",
      endIndex: "",
      loading: undefined,
      total_pages: "",
      page: "",
      per_page: "",
      totalRecord: "",
      rowData: [], //? Data ที่ต่อ API
      // rowUser: [],
      rowAddress: [],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],

      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      paginatedData: "",

      selectedUsers: [],
      isSelectedAll: false,

      selectMode: "multi",
      selected: [],
      filter: {
        nameThPros: "",
        familyNameThPros: "",
        licensePlate: "",
        regCode: "",
        familyNameTh: "",
        nameTh: "",
        regDate: "",
        paymentCode: "",
      },
      totalPageVe: "",
      perPageVe: 10,
      currentPageVe: 1,
      totalRecordVe: "",
      pageOptionsVe: [5, 10, 25, 50, 100],

      totalPagePros: "",
      perPagePros: 10,
      currentPagePros: 1,
      totalRecordPros: "",
      pageOptionsPros: [5, 10, 25, 50, 100],
      fieldsVe: [
        {
          key: "index",

          label: "ลำดับ",
        },
        {
          key: "vin",
          sortable: true,
          label: "เลขตัวถัง",
        },
        {
          key: "ownerNameTh",
          sortable: true,
          label: "เจ้าของรถ",
        },
        {
          key: "driverNameTh",
          sortable: true,
          label: "คนขับ",
        },

        {
          key: "licensePlate",
          sortable: true,
          label: "ทะเบียนรถ",
        },
        {
          key: "vehicleBrandTh",
          sortable: true,
          label: "ยี่ห้อ",
        },
        {
          key: "vehicleModelTh",
          sortable: true,
          label: "รุ่น",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      fieldsPros: [
        {
          key: "index",

          label: "ลำดับ",
        },
        {
          key: "ppCusCode",
          sortable: true,
          label: "รหัสลูกค้ามุ่งหวัง",
        },
        {
          key: "nameTh",
          sortable: true,
          label: "ชื่อลูกค้า",
        },

        {
          key: "familyNameTh",
          label: "นามสกุล",
          sortable: true,
        },
        {
          key: "cusSrcNameTh",
          label: "แหล่งที่มา",
          sortable: true,
        },

        {
          key: "occNameTh",
          label: "อาชีพ",
          sortable: true,
        },
        {
          key: "level",
          label: "ความสนใจ",
          sortable: true,
        },
        {
          key: "mobilePhone",
          label: "เบอร์โทร",
          sortable: true,
        },
        {
          key: "email",
          label: "อีเมล์",
        },
        {
          key: "status",
          label: "สถานะ",
          sortable: true,
        },
        {
          key: "branchNameTh",
          label: "สาขา",
          sortable: true,
        },
      ],

      fields: [
        {
          key: "index",

          label: "ลำดับ",
        },
        {
          key: "cusType",
          sortable: true,
          label: "ประเภทลูกค้า",
        },
        {
          key: "nameTh",
          sortable: true,
          label: "ชื่อลูกค้า",
        },
        {
          key: "familyNameTh",
          sortable: true,
          label: "นามสกุลลูกค้า",
        },
        {
          key: "mobilePhone",
          sortable: true,
          label: "เบอร์โทร",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],

      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",
      branchId: "",
      occOption: [],
      rowContact: [],
      ctJobId: this.$route.params.ctJobId,
      vehicaleOption: [],
      contactTypeOption: [],
      customerOption: [],
      prospectOption: [],
      cusSrcOption: [],
      prefixOPtion: [],
      provinces: [],
      amphures: [],
      districts: [],
      statusOption: [
        {
          nameTh: "เสร็จสิ้น",
          id: "S",
        },
        {
          nameTh: "Lost",
          id: "L",
        },
      ],
      formApv: {
        status: "",
      },
      submitApv: false,
      tooltipform: {
        branchId: "",
        nameTh: "",
        familyNameTh: "",
        mobilePhone: "",
        email: "",
        cusSrcId: "",
        occId: "",
        level: "",
        cusId: "",
        ppCusId: "",
        vehicleId: "",
        ctJobTypeId: "",
        ctJobDate: "",
        note: "",
      },
      form: {
        subject: "",
        message: "",
      },
      submit: false,
    };
  },
  validations: {
    tooltipform: {
      ctJobTypeId: {
        required,
      },
      ctJobDate: {
        required,
      },
    },
    form: {
      subject: {
        required,
      },
      message: {
        required,
      },
    },
    formApv: {
      status: {
        required,
      },
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.localDataBranchIdUser = arrayBranch;
    // this.branchId = user.branchId;
    this.localData = user.userAccessBranch;
    this.getDataShow();
    this.getContact();
  },
  computed: {
    fullAddress: function () {
      return `${
        this.tooltipform.buildingNumber != ""
          ? "" + this.tooltipform.buildingNumber
          : this.tooltipform.buildingNumber
      } ${
        this.tooltipform.townshipNumber != ""
          ? "หมู่" + " " + this.tooltipform.townshipNumber
          : this.tooltipform.townshipNumber
      } ${
        this.tooltipform.townshipName != ""
          ? "" + this.tooltipform.townshipName
          : this.tooltipform.townshipName
      } ${
        this.tooltipform.subLane != ""
          ? "ตรอก" + " " + this.tooltipform.subLane
          : this.tooltipform.subLane
      } ${
        this.tooltipform.lane != ""
          ? "ซอย" + " " + this.tooltipform.lane
          : this.tooltipform.lane
      } ${
        this.tooltipform.streetName != "" &&
        this.tooltipform.streetName != undefined
          ? "ถนน" + " " + this.tooltipform.streetName
          : ""
      } ${
        this.districtId != "" &&
        this.districtId != undefined &&
        this.districtId != null &&
        this.districtId.nameTh != "" &&
        this.districtId.nameTh != undefined &&
        this.districtId.nameTh != null
          ? "ตำบล" + this.districtId.nameTh
          : ""
      } ${
        this.amphurId != "" &&
        this.amphurId != undefined &&
        this.amphurId != null &&
        this.amphurId.nameTh != "" &&
        this.amphurId.nameTh != undefined &&
        this.amphurId.nameTh != null
          ? "อำเภอ" + this.amphurId.nameTh
          : ""
      } ${
        this.provinceId != "" &&
        this.provinceId != null &&
        this.provinceId != undefined &&
        this.provinceId.nameTh != null &&
        this.provinceId.nameTh != "" &&
        this.provinceId.nameTh != undefined
          ? "จังหวัด" + this.provinceId.nameTh
          : ""
      }  ${
        this.tooltipform.postCode != ""
          ? "รหัสไปรษณีย์" + " " + this.tooltipform.postCode
          : ""
      }
      `;
    },
  },
  created() {
    this.getContactType();
  },
  methods: {
    time(SECONDS) {
      var time = new Date(SECONDS * 1000).toISOString().substr(11, 8);
      return time;
    },
    checkContactType(data) {
      if (data === "MANUAL") {
        return "ผ่านเว็บ";
      } else if (data === "CALL") {
        return "โทร";
      }
    },
    hide() {
      this.$refs["modalContact"].hide();
      this.submit = false;
    },
    select(data) {
      this.data = data[0];

      this.tooltipform.ppCusId = {
        nameTh: `${this.data.nameTh != null ? this.data.nameTh : ""} ${
          this.data.familyNameTh != null ? this.data.familyNameTh : ""
        }`,
        ppCusId: this.data.ppCusId,
      };
      this.tooltipform.mobilePhone = this.data.mobilePhone;
      this.tooltipform.email = this.data.email;
      this.tooltipform.cusSrcId = { nameTh: this.data.cusSrcNameTh };
      this.tooltipform.occId = { nameTh: this.data.occNameTh };
      this.tooltipform.cusId = "";
      this.$refs["modalPropect"].hide();
    },
    selectCus(data) {
      // const arr = [];
      this.data = data[0];
      this.tooltipform.cusId = {
        nameTh: `${this.data.nameTh != null ? this.data.nameTh : ""} ${
          this.data.familyNameTh != null ? this.data.familyNameTh : ""
        }`,
        cusId: this.data.cusId,
      };
      this.tooltipform.mobilePhone = this.data.mobilePhone;
      this.tooltipform.email = this.data.email;
      this.tooltipform.ppCusId = "";
      this.$refs["modalCus"].hide();
    },
    selectVe(data) {
      this.data = data[0];

      this.tooltipform.vehicleId = {
        licensePlate: this.data.licensePlate,
        vehicleId: this.data.vehicleId,
      };
      this.tooltipform.vin = this.data.vin;
      this.tooltipform.vehicleModelTh = this.data.vehicleModelTh;
      this.tooltipform.vehicleBrandTh = this.data.vehicleBrandTh;
      this.$refs["modalVe"].hide();
    },
    selectCusNoArr(item) {
      this.tooltipform.mobilePhone = item.mobilePhone;
      this.tooltipform.email = item.email;
      this.tooltipform.ppCusId = "";
    },
    selectNoArr(item) {
      this.tooltipform.mobilePhone = item.mobilePhone;
      this.tooltipform.email = item.email;

      this.tooltipform.cusId = "";
    },
    selectVeNoArr(item) {
      this.tooltipform.vin = item.vin;
      this.tooltipform.vehicleModelTh = item.vehicleModelTh;
      this.tooltipform.vehicleBrandTh = item.vehicleBrandTh;
    },
    customLabelCus({ nameTh, familyNameTh }) {
      return `${nameTh != null ? nameTh : ""}  ${
        familyNameTh != null ? familyNameTh : " "
      }`;
    },
    ChangeNameTh(nameTh) {
      if (nameTh.nameTh === "นาย") {
        this.tooltipform.prefixIdEn = "Mr.";
      } else if (nameTh.nameTh === "นาง") {
        this.tooltipform.prefixIdEn = "Mrs.";
      } else {
        this.tooltipform.prefixIdEn = "Miss.";
      }
    },

    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchItem = user.branchId;

      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;
    },

    Form() {
      this.$v.$touch();
      this.submitform = true;
      if (this.$v.tooltipform.$invalid != true) {
        this.putContactJob();
      }
    },
    FormApv() {
      this.$v.$touch();
      this.submitApv = true;
      if (this.$v.formApv.$invalid != true) {
        this.putSubmit();
      }
    },
    ContactForm() {
      this.$v.$touch();
      this.submit = true;
      if (this.$v.form.$invalid != true) {
        this.postContactDetail();
      }
    },
    getContact: function () {
      this.overlayFlag = true;

      useNetw
        .get("api/sale-contact-job/contact", {
          params: {
            ctJobId: atob(this.ctJobId),
          },
        })
        .then((response) => {
          this.rowContact = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message + "<br>" + err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataShow: function () {
      this.overlayFlag = true;

      useNetw
        .get("api/sale-contact-job/show", {
          params: {
            ctJobId: atob(this.ctJobId),
          },
        })
        .then((response) => {
          this.rowData = response.data.data;
          this.branchId = response.data.data.branchId;
          this.tooltipform.branchId = {
            nameTh: response.data.data.branchNameTh,
            branchId: response.data.data.branchId,
          };
          this.status = response.data.data.status;

          if (response.data.data.cusId != null) {
            this.tooltipform.cusId = {
              nameTh: `${response.data.data.nameTh} ${
                response.data.data.familyNameTh != null
                  ? response.data.data.familyNameTh
                  : ""
              }`,
              cusId: response.data.data.cusId,
            };
            this.tooltipform.mobilePhone = response.data.data.mobilePhone;
            this.tooltipform.email = response.data.data.email;
            this.tooltipform.note = response.data.data.note;
          } else if (response.data.data.ppCusId != null) {
            this.tooltipform.ppCusId = {
              nameTh: `${response.data.data.nameTh} ${
                response.data.data.familyNameTh != null
                  ? response.data.data.familyNameTh
                  : ""
              }`,
              ppCusId: response.data.data.ppCusId,
            };
            this.tooltipform.mobilePhone = response.data.data.mobilePhone;
            this.tooltipform.email = response.data.data.email;
            this.tooltipform.note = response.data.data.note;
          }
          this.tooltipform.ctJobTypeId = {
            nameTh: response.data.data.contactTypeNameTh,
            ctJobTypeId: response.data.data.ctJobTypeId,
          };
          this.tooltipform.ctJobDate = response.data.data.ctJobDate;
          this.tooltipform.vehicleId = {
            licensePlate: response.data.data.licensePlate,
            vehicleId: response.data.data.vehicleId,
          };
          this.form.subject = response.data.data.contactTypeNameTh;
          this.tooltipform.vin = response.data.data.vin;
          this.tooltipform.vehicleBrandTh = response.data.data.brandNameTh;
          this.tooltipform.vehicleModelTh = response.data.data.modelNameTh;
          // this.getProspect();
          this.getCustomer();
          this.getVehical();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message + "<br>" + err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getContactType: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/contact-job-types-c", {})
        .then((response) => {
          this.contactTypeOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    getCustomer: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/sale-contact-job/branch-customer", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId:
              this.tooltipform.branchId !== null
                ? this.tooltipform.branchId.branchId
                : this.branchId,
            nameTh: event != null ? event : this.filter.nameTh,
            familyNameTh: this.filter.familyNameTh,
          },
        })
        .then((response) => {
          this.customerOption = response.data.data;
          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getVehical: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/sale-contact-job/branch-vehicle", {
          params: {
            page: this.currentPageVe,
            perPage: this.perPageVe,
            branchId:
              this.tooltipform.branchId !== null
                ? this.tooltipform.branchId.branchId
                : this.branchId,
            licensePlate: event != null ? event : this.filter.licensePlate,
          },
        })
        .then((response) => {
          this.vehicaleOption = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecordVe = response.data.total;
          this.to = response.data.to;
          this.totalPageVe = Math.ceil(this.totalRecordVe / per_Page);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowAddress = filteredItems.length;
      this.currentPage = 1;
    },

    onRowSelected(rowData) {
      this.selected = rowData;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    putContactJob: function () {
      this.overlayFlag = true;

      useNetw
        .put("api/sale-contact-job/update", {
          ctJobId: atob(this.ctJobId),
          cusId:
            this.tooltipform.cusId.cusId != null
              ? this.tooltipform.cusId.cusId
              : "",

          // ppCusId:
          //   this.tooltipform.ppCusId.ppCusId != null
          //     ? this.tooltipform.ppCusId.ppCusId
          //     : "",
          vehicleId:
            this.tooltipform.vehicleId.vehicleId != null
              ? this.tooltipform.vehicleId.vehicleId
              : "",
          ctJobTypeId:
            this.tooltipform.ctJobTypeId.ctJobTypeId != null
              ? this.tooltipform.ctJobTypeId.ctJobTypeId
              : "",
          ctJobDate: this.tooltipform.ctJobDate,
          note: this.tooltipform.note,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    postContactDetail: function () {
      this.overlayFlag = true;

      useNetw
        .post("api/sale-contact-job/contact/store", {
          ctJobId: atob(this.ctJobId),
          subject: this.form.subject,
          message: this.form.message,
        })
        .then((response) => {
          this.getContact();
          this.clearPost();
          this.getDataShow();
          this.$refs["modalContact"].hide();
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    putSubmit: function () {
      this.overlayFlag = true;

      useNetw
        .put("api/sale-contact-job/submit", {
          ctJobId: atob(this.ctJobId),
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "sale-contact-job",
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    clearPost() {
      this.form.subject = "";
      this.form.message = "";

      this.submit = false;
    },
    deleteContact: function (rowData) {
      this.Data = rowData;
      // console.log(this.partData);
      this.loading = true; //skeleton true
      useNetw
        .delete("api/sale-contact-job/contact/delete", {
          data: {
            contactId: this.Data,
          },
        })
        .then((response) => {
          // alert("ต้องการลบหรือไม่")
          this.getContact();
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    alert(rowData) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "ลบ",
          cancelButtonText: "ยกเลิก",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deleteContact(rowData);
          }
        });
    },
    handleSearch() {
      if (this.tooltipform.branchId === "") {
        this.branchId;
      } else {
        this.branchId = this.tooltipform.branchId.branchId;
      }
      this.getCustomer();
    },
    handleSearchVe() {
      if (this.tooltipform.branchId === "") {
        this.branchId;
      } else {
        this.branchId = this.tooltipform.branchId.branchId;
      }
      this.getVehical();
    },
    handleSearchPros() {
      if (this.tooltipform.branchId === "") {
        this.branchId;
      } else {
        this.branchId = this.tooltipform.branchId.branchId;
      }
      this.getProspect();
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.getCustomer();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getCustomer();
    },
    handleChangePageVe(page) {
      this.currentPage = page;
      this.getVehical();
    },
    handlePageChangeVe(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getVehical();
    },

    handleChangePagePros(page) {
      this.currentPage = page;
      this.getProspect();
    },
    handlePageChangePros(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getProspect();
    },
  },
};
</script>
<style scoped>
.input-placeholder {
  font-size: 12px;
}
</style>
